var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"recommended-products container"},[(_vm.products && _vm.products.length)?[_c('div',{staticClass:"recommended-products-tabs"},[_c('div',{staticClass:"container d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex flex-column justify-content-between align-items-center flex-md-row"},[_vm._m(0),(_vm.showCountDown)?_c('div',{staticClass:"counterDown"},[(
                _vm.products &&
                  _vm.products.length &&
                  _vm.products[0].discount_until != null
              )?_c('flip-countdown',{attrs:{"countdownSize":"1.35rem","deadline":_vm.getExpireDate(_vm.products[0]),"showLabels":false,"showDays":false,"labels":{
                days: '',
                hours: '',
                minutes: '',
                seconds: '',
              }}}):_vm._e()],1):_vm._e()]),_c('router-link',{staticClass:"seeMoreHomeLink fontsize-medium text-light",attrs:{"to":_vm.link}},[_vm._v("مشاهده همه")])],1)]),_c('div',{staticClass:"recommended-content"},[_c('div',{staticClass:"recommended-content-item position-relative",attrs:{"id":"home"}},[_c('div',[(!_vm.disabledSliders.includes('recommendedProductSlider'))?_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.products),function(product,index){return _c('Product',{key:index + 'index',staticClass:"swiper-slide",style:('max-width:' + _vm.maxWidth + 'px'),attrs:{"showCountDown":_vm.showCountDown,"product":product}})}),1)]):_vm._e()])])]),_c('div',{staticClass:"next-btn-holder d-none d-xl-block"},[_c('div',{staticClass:"next-btn"},[_c('div',{staticClass:"next-btn-icon"},[_c('RightChevIcon')],1)])]),_c('div',{staticClass:"prev-btn-holder d-none d-xl-block"},[_c('div',{staticClass:"prev-btn"},[_c('div',{staticClass:"prev-btn-icon"},[_c('LeftChevIcon')],1)])])]:[_c('Loading',{attrs:{"mode":'dot'}})]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"fontsize-medium text-light m-0"},[_c('strong',[_vm._v("محصولات")]),_vm._v(" شگفت انگیز ")])}]

export { render, staticRenderFns }