var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('slider'),_c('div',{},[(_vm.homeData)?[(false)?_c('stylesProducts',{attrs:{"productTitle":'استالیتو بساز',"uniqId":"productSliderSectionStyle","products":_vm.homeData.mostDiscount,"link":"/products/?sort=most_discount"}}):_vm._e(),(_vm.homeData)?[(_vm.homeData.mostDiscount.length)?_c('AmazingProductBox',{attrs:{"showCountDown":true,"productTitle":'حراج شگفت انگیز',"products":_vm.homeData.mostDiscount,"link":"/products/?sort=most_discount"}}):_vm._e()]:_vm._e(),_c('RecommendedProductSlider'),_vm._l((_vm.homeData.specialProducts),function(item,index){return _c('ProductsSlider',{key:item.header,attrs:{"productTitle":item.header,"uniqId":("productSliderSection" + index),"products":item.products,"link":("/category/" + (item.catId))}})})]:_vm._e(),(_vm.homeData)?[(
          _vm.homeData.mostSalesLast7Days && _vm.homeData.mostSalesLast7Days.length
        )?_c('ProductsSlider',{attrs:{"productTitle":'پرفروش ترین ها',"uniqId":"productSliderSectionMostSales","products":_vm.homeData.mostSalesLast7Days,"link":"/products/?sort=top_sales"}}):_vm._e()]:_vm._e(),_c('br'),_c('main',{staticClass:"main"},[_c('div',[_c('ResponsiveBannerDouble',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center_1'),"item2":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center_2')}}),_c('ResponsiveBanner',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.center'),"mobile-item":_vm.access(
              _vm.$store.getters,
              'front/getHomeData.advertise.center_mobile'
            )}})],1),(
          _vm.$store.getters['front/getHomeData'] != null &&
            _vm.$store.getters['front/getHomeData'].flashes != ''
        )?_c('Flashes',{attrs:{"flashes":_vm.$store.getters['front/getHomeData'].flashes}}):_vm._e(),_c('div',[_c('ResponsiveBanner',{staticClass:"w-100",attrs:{"item":_vm.access(_vm.$store.getters, 'front/getHomeData.advertise.bottom'),"mobile-item":_vm.access(
              _vm.$store.getters,
              'front/getHomeData.advertise.bottom_mobile'
            )}})],1),_c('Banners',{attrs:{"banners":_vm.banners[1]}}),_c('weblogs')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }